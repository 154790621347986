import type { MaybeRefOrGetter } from 'vue'
import { useMFILogger } from '~/composables/logger'

function getAdClass(typeClass: string, devClass: string = '') {
  const config = useRuntimeConfig()

  const isDev = config.public.isDev

  return `adslot ${typeClass} ${isDev ? devClass : ''}`
}

export function useAds(
  params: {
    loading: Array<MaybeRefOrGetter>
    lazyLoadedAds: Ref<Array<HTMLElement> | null>
  } = {
    loading: [computed(() => false)],
    lazyLoadedAds: ref([])
  }
) {
  const { loading = [computed(() => false)], lazyLoadedAds = ref([]) } = params
  const { logger } = useMFILogger()

  const _adsClassesMap = {
    // Video outstream
    outstream: getAdClass('outstream'),
    // Desktop: 728x90, Tablet: 728x90, Mobile: 320x50
    bannerOnly: getAdClass('banneronly bg-gray'),
    // Desktop: 728x90-970x250, Tablet: 728x90, Mobile: 320x50-320x100
    banner: getAdClass('banner bg-gray'),
    // Desktop: 300x250, Tablet: 300x250, Mobile: 300x250
    boxOnly: getAdClass('boxonly bg-gray'),
    // Desktop: 300x250-300x600, Tablet: 300x250-300x600, Mobile: 300x250-300x600
    box: getAdClass('box bg-gray'),
    // Desktop: 160x600, Tablet: 160x600, Mobile: 160x600
    sky: getAdClass('sky bg-gray'),
    // Desktop: 1800x800, Tablet: parallax ou fullscreen, Mobile: parallax ou fullscreen
    wallpaper: getAdClass('wallpaper')
  } as const

  const adsClassesMap = _adsClassesMap as Readonly<typeof _adsClassesMap>

  const ssrRenderedIgnored = useState('ssrRenderedAds', () => import.meta.server)

  const addLazyAdsWatcher = () => {
    watch(
      lazyLoadedAds,
      (_lazyLoadedAds) => {
        if (_lazyLoadedAds && _lazyLoadedAds.length) {
          logger.info('Detected a new lazy loaded ad')
          initializeLazyloading()
        }
      },
      {
        deep: true
      }
    )
  }

  onMounted(() => {
    if (ssrRenderedIgnored.value) {
      ssrRenderedIgnored.value = false
      addLazyAdsWatcher()
    }

    const stopInitializeWatcher = watch(
      loading,
      (_loading) => {
        if (_loading.every((l) => !l)) {
          nextTick(() => {
            try {
              initializeAds()
              logger.success('Initialize ads (SPA)')
              addLazyAdsWatcher()
            } catch (err) {
              logger.error('Error initializing ads (SPA)', err)
            }
            stopInitializeWatcher()
          })
        }
      },
      {
        immediate: true
      }
    )
  })

  return { adsClassesMap }
}
